import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../utils/axios";

const emailRegex =
  /(?:[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

function ResultsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [fullreportModal, setFullReportModal] = useState(false);
  const [loading, setLoading] = useState({
    active: false,
    action: "",
  });
  const [error, setError] = useState({
    status: false,
    message: "",
    action: "", //this will used to tell error occur in which step
  });
  const [data, setData] = useState({
    name: "",
    email: "",
    address: "",
  });
  const [riskMeter, setRiskMeter] = useState('/assets/images/solar-checkr-green.png');

  const state = location.state;
  const file = state?.file;
  const redFlags = state?.redFlags || {};
  const yellowFlags = state?.yellowFlags || {};
  const greenFlags = state?.greenFlags || {};
  useEffect(() => {
    if (!file || !redFlags || !yellowFlags || !greenFlags) {
      navigate("/contract-upload");
    }
    else if (Object.keys(redFlags).length > 0) {
      setRiskMeter('/assets/images/solar-checkr-red.png');
    }
    else if (Object.keys(yellowFlags).length > 0) {
      setRiskMeter('/assets/images/solar-chekr-results.png');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

const handleFullReportSubmit = async (e) => {
  if (
    !file ||
    !data.name ||
    !data.email ||
    !data.address ||
    !emailRegex.test(data.email)
  ) {
    setError({
      status: true,
      message: "Please fill all fields to continue",
      action: "submit",
    });
    return;
  }
  setError({
    status: false,
    message: "",
    action: "",
  });
  try {
    setLoading({
      active: true,
      action: "submit",
    });

    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("address", data.address);

    const response = await axios.post(`/ask-premium-report/`, formData);

    // Close the modal
    setFullReportModal(false);

    // Redirect to the Stripe payment link
    window.location.href = "https://buy.stripe.com/eVa4j47XD16vciQ6oo";
  } catch (err) {
    console.log(err);

    setError({
      status: true,
      message: err.response?.data?.message || "Something went wrong",
      action: "submit",
    });
  } finally {
    setLoading({
      active: false,
      action: "",
    });
  }
};
  const renderFullReportModal = (
    <Modal
      show={fullreportModal}
      centered
      onHide={() => setFullReportModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Full Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* name */}
          <Form.Group className='mb-3' controlId='form-name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='email'
              value={data.name}
              onChange={(event) =>
                setData((d) => {
                  return {
                    ...d,
                    name: event.target.value,
                  };
                })
              }
              placeholder='Full name'
              autoFocus
            />
          </Form.Group>
          {/* email */}
          <Form.Group className='mb-3' controlId='form.email'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type='email'
              value={data.email}
              onChange={(event) =>
                setData((d) => {
                  return {
                    ...d,
                    email: event.target.value,
                  };
                })
              }
              placeholder='name@example.com'
            />
          </Form.Group>
          {/* address */}
          <Form.Group className='mb-3' controlId='form.address'>
            <Form.Label>Address</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              value={data.address}
              onChange={(event) =>
                setData((d) => {
                  return {
                    ...d,
                    address: event.target.value,
                  };
                })
              }
            />
          </Form.Group>
          {error.status && error.action === "submit" && (
            <div className='d-flex justify-content-center mt-2'>
              <p className='text-danger text-center m-1'> {error.message}</p>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setFullReportModal(false)}>
          Cancel
        </Button>
        <Button
          variant='primary'
          style={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleFullReportSubmit}
        >
          {loading?.active && loading?.action === "submit" && (
            <Spinner
              animation='grow'
              size='sm'
              style={{ color: "inherit", marginRight: "10px" }}
            />
          )}{" "}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return (
    <>
      <div className='home-page-wrapper'>
        <section className='banner-section'>
          <Container fluid>
            <Container>
              <Row className='align-items-center'>
                <Col md={12}>
                  <div className='banner-content results-banner-content'>
                    <h3>Your Results Are In</h3>
                    <div className='banner-image'>
                      <img
                        src={riskMeter}
                        alt='Results'
                      />
                    </div>
                    {Object.keys(redFlags).length > 0 && (
                      <h4>
                        We found {Object.keys(redFlags).length} red flags, {Object.keys(yellowFlags).length} yellow flags, and {Object.keys(greenFlags).length} green flags in your contract.
                      </h4>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className='result-section'>
          <Container fluid>
            <Container>
              <Row>
                <Col md={12}>
                  <div className='result-main-area'>
                    {Object.keys(redFlags).map((key, i) => (
                      <div
                        className='result-box'
                        //result-main-box  installer-bg-color
                        key={i}
                      >
                        
                        <img
                          src='/assets/images/red_slider.png'
                          style={{ width: "100%", height: "100%" }}
                          alt='red_slider'
                        />
                        <p>{redFlags[key]}</p>
                      </div>
                    ))}
                    {Object.keys(yellowFlags).map((key, i) => (
                      <div
                        // className='result-main-box cost-bg-color'
                        className='result-box'
                        key={i}
                      >
                        
                        <img
                          src='/assets/images/yellow_slider.png'
                          style={{ width: "100%", height: "100%" }}
                          alt='yellow_slider'
                        />
                        <p>{yellowFlags[key]}</p>
                      </div>
                    ))}
                    {Object.keys(greenFlags).map((key, i) => (
                      <div
                        // className='result-main-box contract-bg-color'
                        className='result-box'
                        key={i}
                      >
                        
                        <img
                          src='/assets/images/green_slider.png'
                          style={{ width: "100%", height: "100%" }}
                          alt='green_slider'
                        />
                        <p>{greenFlags[key]}</p>
                      </div>
                    ))}

                    {/* <div className='result-main-box installer-bg-color'>
                      <h5>Installer</h5>
                      <p>[JSON Output]</p>
                    </div>
                    <div className='result-main-box equipment-bg-color'>
                      <h5>Equipment</h5>
                      <p>[JSON Output]</p>
                    </div> */}
                    {renderFullReportModal}
                    <div
                      className='text-center'
                      style={{ cursor: "pointer" }}
                      onClick={() => setFullReportModal(true)}
                    >
                      <img
                        src='/assets/images/get-report-button-image.png'
                        alt='Button'
                        className='get-report-btn'
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className='contract-detail-section'>
          <Container fluid>
            <Container>
              <Row>
                <Col md={12}>
                  <div className='contract-detail-main'>
                    <h1>What Does This Mean?</h1>
                    <div className='contract-detail-box cost-bg-color'>
                      <h2>Cost</h2>
                      <p>
                        We compare your quoted cost what other customers are
                        paying across the country to tell you if the total cost
                        you’ve been quoted aligns with what others are paying. A
                        low score signifies the cost is not competitive with
                        what we’re seeing other similar customers get. There are
                        many factors that go into a fair solar price, and our
                        Full Report will help you understand how we came to this
                        score.
                      </p>
                    </div>
                    <div className='contract-detail-box contract-bg-color'>
                      <h2>Contract</h2>
                      <p>
                        We analyze the some of the most common terms in your
                        contract that could turn a good deal into a bad one.
                        These are things like escalator rate, warranty, and
                        liabilities. All long-term contracts have some level of
                        risk, but we help you to spot the biggest red-flags in
                        the market.
                      </p>
                    </div>
                    <div className='contract-detail-box installer-bg-color'>
                      <h2>Installer</h2>
                      <p>
                        Most installers are reputable organizations with good
                        intentions, but there are bad actors out there. We
                        leverage proprietary information about the reputation
                        and track record of the most relevant players int he
                        market to help you choose the right partner for your
                        solar journey.
                      </p>
                    </div>
                    <div className='contract-detail-box equipment-bg-color'>
                      <h2>Equipment</h2>
                      <p>
                        Some solar equipment is just better than others. We help
                        to evaluate the brand of key components like panels and
                        inverters that will help you make an investment in
                        reliable, efficient equipment.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </div>
    </>
  );
}

export default ResultsPage;
