import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';






function Home() {
    return (
      <>
        <div className="home-page-wrapper">
          <section className="banner-section">
            <Container fluid>
              <Container>
                <Row className="align-items-center">
                  <Col md={7}>
                    <div className="banner-content">
                      <h1>Get Your Free Solar Check Today</h1>
                      <img src="/assets/images/heading-line.png" alt="Line" />
                      <p>
                        Get a free deal rating and red-flag count broken down by
                        key sections. 100% Free.
                      </p>
                      <Link to="/contract-upload" className="btn btn-primary">
                        Check Your Deal
                      </Link>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="banner-image">
                      <img
                        src="/assets/images/new_phone_rbg.png"
                        alt="Phone 4"
                        style={{ width: "107%" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          </section>

          <section className="why-check-solar-section">
            <Container fluid>
              <Container>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="why-check-solar-image">
                      <img
                        src="/assets/images/solar-check-image.png"
                        alt="Solar"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="why-check-solar-content">
                      <h3>Why you need a Solar Check</h3>
                      <p>
                        Solar can be a great choice for you, your home, and the
                        environment - but it can also lock you into a 20+ year
                        contract that sends your bill through the roof.
                      </p>
                      <p>
                        SolarCheckr reviews your offer, provides you a simple
                        deal score and identifies over 20 of the most common
                        red-flags to help you make sure you’re getting a good
                        deal - for today and tomorrow.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          </section>

          <section className="solar-deals-section">
            <Container fluid>
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="solar-deals-main-heading">
                      <h1>
                        Learn more about how we protect you from bad solar deals
                      </h1>
                      <Link
                        to="/about#solar-data-secure"
                        className="btn btn-light"
                      >
                        Learn More
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="solar-deals-content">
                      <h3>What is a Solar Check?</h3>
                      <p>
                        A detailed review of a proposal or contract from a
                        developer. We analyze the offer, compare the cost and
                        terms to industry best practices, identify red flags and
                        provide you with a simple score and count of the
                        red-flags we identified.
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="solar-deals-image">
                      <img
                        src="/assets/images/solar-deals-image.png"
                        alt="Contract"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          </section>

          <section className="data-secure-section">
            <Container fluid>
              <Container>
                <Row className="align-items-center">
                  <Col md={5}>
                    <div className="data-secure-image">
                      <img
                        src="/assets/images/data-secure-image.png"
                        alt="Solar"
                      />
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="data-secure-content">
                      <h3>Is my data secure?</h3>
                      <p>
                        SolarCheckr will not sell your personal information to
                        third parties, and all data is transferred via a secure,
                        encrypted connection. You will never receive a sales
                        call as a result of getting a Solar Check.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          </section>

          <section className="solar-check-cost-section">
            <Container fluid>
              <Container>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="solar-check-cost-content">
                      <h3>How much does a Solar Check cost?</h3>
                      <p>
                        A basic review is free, premium reports with deeper
                        analysis and customized strategies to help you negotiate
                        or find a better deal are $100.
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="solar-check-cost-image">
                      <img
                        src="/assets/images/solar-check-cost-image.png"
                        alt="Solar"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          </section>
        </div>
      </>
    );
}





export default Home;
