import React from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import axios from "../utils/axios";

function Footer() {

  const [subscriptionSuccess, setSubscriptionSuccess] = useState('');
  const [isUploadingForm, setIsUploadingForm] = useState(false);

  const subscriptionFormSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      setIsUploadingForm(true);
      const response = await axios.post('/subscribe', formData);
      setSubscriptionSuccess(response?.data?.status);
      setIsUploadingForm(false);
      e.target.reset();
    } catch (error) {
      setSubscriptionSuccess('error');
      setIsUploadingForm(false);
    }
  }

  return (
    <>
      <footer>
        <Container fluid>
          <Container>
            <Row className='align-items-center'>
              <Col md={6}>
                <div className='footer-top-content'>
                  <h3>Embark on Your Solar Journey with Confidence</h3>
                  <p>
                    We encourage visitors to explore the world of solar energy
                    with SolarCheckr as their guide. By providing transparent,
                    comprehensive reviews and up-to-date industry insights, we
                    empower homeowners to make informed decisions that align
                    with their values and needs.
                  </p>
                  <Link to='/about'>Learn More</Link>
                </div>
              </Col>
              <Col md={6}>
                <div className='footer-top-image'>
                  <img
                    src='/assets/images/solar-graphic-image.png'
                    alt='Solar Graphic'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid>
          <Container>
            <div className='footer-end-main'>
              <Row className='align-items-center'>
                <Col md={6}>
                  <div className='footer-end-content'>
                    <div className='footer-logo'>
                      <img
                        src='/assets/images/footer-logo.png'
                        alt='Solar Chekr'
                      />
                    </div>
                    <ul>
                      <li>
                        <Link to='/'>Home</Link>
                      </li>
                      <li>
                        <Link to='/about'>About</Link>
                      </li>
                      <li>
                        <Link to='/contact-us'>Contact</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='footer-newsletter'>
                    <h3>Join Our Newsletter</h3>
                    <Form onSubmit={subscriptionFormSubmit}>
                      <div className="d-flex flex-column">
                    <Form.Group className='mb-2' controlId='Name'>
                        <Form.Control
                          type='text'
                          name='name'
                          placeholder='Enter your Name'
                          required
                        />
                      </Form.Group>
                      <Form.Group className='' controlId='email'>
                        <Form.Control
                          type='email'
                          name='email'
                          placeholder='Enter your mail'
                          required
                        />
                      </Form.Group>
                      {subscriptionSuccess && <p className={subscriptionSuccess === 'success' ?'text-success' : 'text-danger'}>{subscriptionSuccess === 'success' ? 'Subscribed successfully' : 'Failed to Subscribe'}</p>}
                      </div>
                      <Button variant='primary' type="submit">
                        {isUploadingForm && <Spinner
                          animation='grow'
                          size='sm'
                          style={{ color: "inherit", marginRight: "10px" }}
                        />}
                        Subscribe
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <Container>
            <div className='footer-end-copyright'>
              <Row className='align-items-center'>
                <Col md={6}>
                  {/* removed */}
                  {/* <div className='footer-copyright-left'>
                                    <p>© 2024 Built by Themes Awesome</p>
                                </div> */}
                </Col>
                <Col md={6}>
                  <div className='footer-copyright-right'>
                    <ul>
                      <li>
                        <Link to='/'>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to='/terms-of-service'>Terms of Service</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
