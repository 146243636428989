import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import Header from './compunents/Header';
import Footer from './compunents/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import ReactGA from 'react-ga4';
import { getAnalytics } from "firebase/analytics";
import app from './utils/react-ga';

function App() {

    useEffect(() => {
        ReactGA.initialize('G-2SZ7B516TG');
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);

    getAnalytics(app);

    return (<>
        <div className="main-wrapper">
           
            <BrowserRouter basename={"/"}>
                <Header />
                <Router />
                <Footer />
            </BrowserRouter>
            
        </div>
    </>);
}

export default App;
