import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenuHandel = () => {
    setMenuOpen(true);
  };

  const closeMenuHandel = () => {
    setMenuOpen(false);
  };
  return (
    <>
      <header>
        <div className="logo">
          <Link to="/">
            <img src="/assets/images/logo.png" alt="Solar Chekr" />
          </Link>
        </div>
        <Button className="menu-open" onClick={openMenuHandel}>
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <div className={menuOpen ? "main-menu menu-active" : "main-menu"}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
            <li>
              <a
                href="https://wordpress-ycc2.onrender.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
          </ul>
          <Button
            variant="secondary"
            className="menu-close"
            onClick={closeMenuHandel}
          >
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </div>
      </header>
    </>
  );
}

export default Header;
