import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import axios from '../utils/axios';
import { useEffect, useState } from 'react';

const Contact = () => {

    const [message, setMessage] = useState('');
    const [isUploadingForm, setIsUploadingForm] = useState(false);

    const onMessageSubmit = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData(e.target);
            setIsUploadingForm(true);
            await axios.post('/contact-us/', formData);
            setIsUploadingForm(false);
            setMessage('success');
            e.target.reset();
        }
        catch (error) {
            setMessage('error');
            setIsUploadingForm(false);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setMessage('');
        }
        , 5000);
        window.scrollTo(0, 0);
    }, [message]); 

    return (
        <div>
            {/* toast message */}
            {message && (
                <div className={`toast-wrapper`}>
                    <p className={`toast show toast-${message}`}>{message === 'success' ? 'Message sent successfully' : 'Failed to send message'}</p>
                </div>
            )}
        <section className='my-5'>
            <Container>
            <Row className='align-items-center'>
            {/* <Col md={6}>
                
                </Col> */}
                <Col md={12}>
                <div className='contact-us'>
                    <div className='contact-image'>
                        <img
                        src="/assets/images/logo.png"
                        alt='Contact Image'
                        />
                    </div>
                    <h2>Get in Touch</h2>
                    <p>
                   We are here to help you with all your solar evaluation questions and concerns. Reach out to us and we’ll get back to you as soon as possible.
                    </p>
                    <Form className='w-100' onSubmit={onMessageSubmit}>
                        <Row>
                            <Col md={6}>
                            <Form.Group className='mb-2' controlId='Name'>
                                <Form.Control
                                type='text'
                                name='name'
                                placeholder='Name'
                                required
                                />
                            </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group className='mb-2' controlId='email'>
                                <Form.Control
                                type='email'
                                name='email'
                                placeholder='Email'
                                required
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                    <Form.Group className='mb-2' controlId='message'>
                        <Form.Control
                        as='textarea'
                        name='message'
                        placeholder='Your message'
                        required
                        />
                    </Form.Group>
                    <Button type='submit' className='mt-3'>
                        {
                            isUploadingForm && <Spinner
                            animation='grow'
                            size='sm'
                            style={{ color: "inherit", marginRight: "10px" }}
                          />
                        }
                        Send Message</Button>
                    </Form>
                </div>
                </Col>
            </Row>
            </Container>
        </section>
        </div>
    );
    }

    export default Contact;
