import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function AfterPayment() {
  return (
    <div className="after-payment-wrapper">
      <section className="thank-you-section">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <div className="thank-you-content">
                <img
                  src="/assets/images/logo.png"
                  alt="SolarCheckr"
                  className="thank-you-logo"
                />
                <h1>Thank You for Your Purchase</h1>
                <p>We will get back to you soon with the premium report.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default AfterPayment;
