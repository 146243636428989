import { Navigate, useRoutes } from 'react-router-dom';
import Home from './pages/Home';
import ContractUpload from './pages/ContractUpload';
import ResultsPage from './pages/ResultsPgae';
import TermsOfService from './pages/TermsOfService';
import About from './pages/About';
import Contact from './pages/Contact';
import AfterPayment from './pages/AfterPayment';





export default function Router() {
    const routes = useRoutes([
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/contract-upload',
            element: <ContractUpload />
        },
        {
            path: '/results',
            element: <ResultsPage />
        },
        {
            path: '/about',
            element: <About />
        },
        {
            path: '/terms-of-service',
            element: <TermsOfService />
        },
        {
            path: '/contact-us',
            element: <Contact />
        },
        {
            path: '/after-payment',
            element: <AfterPayment />
        },
        {
            path: '*',
            element: <Navigate to="/" />
        }
    ]);

    return routes;
}