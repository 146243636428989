import { Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="my-5">
        <Container fluid>
          <Container>
            <Row className="align-items-center">
              <Col md={12}>
                <div className="terms-of-service">
                  <img
                    src="/assets/images/logo.png"
                    alt="Logo"
                    height={80}
                    width={"auto"}
                  />
                  <h1>Terms of Service for SolarCheckr</h1>
                  <div>
                    <h2>Introduction</h2>
                    <p>
                      Welcome to Ohm Digital Solutions. By accessing and using
                      our services, including SolarCheckr, you agree to comply
                      with and be bound by the following terms and conditions.
                      These terms are designed to ensure that our services are
                      used responsibly and ethically.
                    </p>

                    <h2>Personal Use Only</h2>
                    <br />
                    <h3>Scope of Use</h3>
                    <ol>
                      <li>
                        Our services and content, including SolarCheckr, are
                        intended solely for your personal use.
                      </li>
                      <li>
                        These services are specifically designed to help you
                        evaluate solar energy contracts and other relevant
                        information for personal decision-making purposes.
                      </li>
                    </ol>
                    <h3>Prohibited Uses</h3>
                    <ol>
                      <li>
                        You are expressly prohibited from using our services and
                        content for any business, commercial, or other
                        non-personal purposes.
                      </li>
                      <li>
                        Unauthorized use of our services, including but not
                        limited to business analytics, competitive analysis, or
                        any commercial activities, is strictly prohibited and
                        deemed unlawful.
                      </li>
                    </ol>
                    <h3>Intellectual Property</h3>
                    <ol>
                      <li>
                        All content, data, and materials provided by Ohm Digital
                        Solutions remain the intellectual property of the
                        Company.
                      </li>
                      <li>
                        You may not reproduce, distribute, or create derivative
                        works from any content provided by Ohm Digital Solutions
                        for any non-personal use without explicit written
                        permission.
                      </li>
                    </ol>

                    <h2>SolarCheckr Specific Terms</h2>
                    <h3>Purpose and Limitations</h3>
                    <ol>
                      <li>
                        SolarCheckr is a tool designed to assist customers
                        considering solar energy by providing relevant
                        information to aid in their decision-making process.
                      </li>
                      <li>
                        While SolarCheckr aims to offer accurate and helpful
                        information, it should not be the sole factor in your
                        decision. We strongly recommend consulting legal and
                        technical experts before making any commitments,
                        especially when signing contracts based on information
                        from SolarCheckr.
                      </li>
                    </ol>
                    <h3>Beta Testing Disclaimer</h3>
                    <ol>
                      <li>
                        SolarCheckr is currently in beta testing. This means
                        that while we strive for accuracy, the results and
                        information provided by SolarCheckr may not be entirely
                        accurate or complete.
                      </li>
                      <li>
                        We are continuously working to improve the tool and
                        appreciate your understanding and patience as we refine
                        it.
                      </li>
                    </ol>
                    <h3>Acknowledgment of Use</h3>
                    <ol>
                      <li>
                        By using SolarCheckr, you acknowledge and accept that
                        the tool is in beta testing, and its results may not be
                        fully accurate.
                      </li>
                      <li>
                        You agree to use SolarCheckr as one of several resources
                        in your decision-making process and to seek additional
                        professional advice as necessary.
                      </li>
                    </ol>

                    <h2>Compliance and Enforcement</h2>
                    <h3>Monitoring and Enforcement</h3>
                    <ol>
                      <li>
                        Ohm Digital Solutions reserves the right to monitor your
                        use of our services to ensure compliance with these
                        terms.
                      </li>
                      <li>
                        Any violation of these terms may result in termination
                        of access to our services and potential legal action.
                      </li>
                    </ol>
                    <h3>Modifications to Terms</h3>
                    <ol>
                      <li>
                        Ohm Digital Solutions reserves the right to modify these
                        terms at any time.
                      </li>
                      <li>
                        Continued use of the services following any changes
                        signifies your acceptance of the new terms.
                      </li>
                    </ol>

                    <h2>Contact Information</h2>
                    <p>
                      For any questions or further information regarding these
                      terms, please contact us at contact@ohmds.com.
                    </p>
                    <p>
                      Thank you for your understanding and cooperation as we
                      work to provide you with the best possible tools and
                      services.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
    </div>
  );
};

export default TermsOfService;
