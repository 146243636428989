import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utils/axios";
const ContractUpload = () =>  {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    active: false,
    action: "",
  });
  const [error, setError] = useState({
    status: false,
    message: "",
    action: "", //this will used to tell error occur in which step
  });
  const [popup, setPopup] = useState({
    isOpen: false,
  });
  const [file, setFile] = useState(null);
  const [userData, setUserData] = useState({
    file: null,
    name: "",
    email: "",
  });

  const closeModal = () => {
    setPopup({
      isOpen: false,
    });
  };

  const handleContactSubmit = (e) => {
    console.log(userData, 'userData in contactPopupRenderer')
    e.preventDefault();
    setUserData({
      ...userData,
      name: e.target.name.value,
      email: e.target.email.value,
    });
    setPopup({
      isOpen: true,
      formFilled: true,
    })
  };

  const handleDisclamerSubmit = async () => {
    console.log(userData, 'userData in disclaimerPopupRenderer')
    try{
      setLoading({
        active: true,
        action: "submit",
      });

      const formData = new FormData();
      formData.append("file", userData.file);
      formData.append("name", userData.name);
      formData.append("email", userData.email);
      formData.append("address", 'NY');
      setPopup({
        isOpen: false,
      });
      const response = await axios.post(`/submit-userinfo/`, formData);
      navigate("/results", {
          state: {
            file: file,
            redFlags: response.data["Red Flags"],
            yellowFlags: response.data["Yellow Flags"],
            greenFlags: response.data["Green Flags"],
          },
        });
      } catch (err) {
        console.log(err);

      setError({
        status: true,
        message: err.response?.data?.message || "Something went wrong",
        action: "submit",
      });
    } finally {
      setLoading({
        active: false,
        action: "",
      });
    }
  }

  const handelFileUpload = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    setError({
      status: false,
      message: "",
      action: "",
    });
    try {
      setFile(e.target.files[0]);
      setUserData({
        ...userData,
        file: file,
      });
      setPopup({
        isOpen: true,
        formFilled: false,
      });
    } catch (err) {
      console.log(err);

      setError({
        status: true,
        message: err.response?.data?.message || "Something went wrong",
        action: "submit",
      });
    } 
  };
  return (
    <>
      <div className='home-page-wrapper'>
        <Modal
          show={popup.isOpen}
          centered
          onHide={closeModal}
        >
          {
            popup.formFilled ? (<>
              <Modal.Header closeButton>
                <Modal.Title>Disclaimer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
            SolarCheckr is a tool designed to help customers considering solar energy. It provides relevant information but shouldn't be the only factor in your decision. It's in beta testing, so results may not be entirely accurate, but we're constantly improving it. We strongly recommend consulting legal and technical experts before committing, especially when signing contracts based on SolarCheckr's information. Your use of SolarCheckr acknowledges these considerations. Thank you for your understanding as we refine this tool.
             <br /><br />
      Please review our <a href="https://www.solarcheckr.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
      
            </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='primary'
                  onClick={handleDisclamerSubmit}
                >
                  {loading?.active && loading?.action === "submit" && (
                          <Spinner
                            animation='grow'
                            size='sm'
                            style={{ color: "inherit", marginRight: "10px" }}
                          />
                        )}{" "}
                  I Understand
                </Button>
              </Modal.Footer>
              </>) : ( <Form onSubmit={handleContactSubmit}>
      <Modal.Body>
          {/* name */}
          <Form.Group className='mb-3' controlId='form.name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='name'
              name="name"
              placeholder='Full name'
              required
              autoFocus
            />
          </Form.Group>
          {/* email */}
          <Form.Group className='mb-3' controlId='form.email' >
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type='email'
              name="email"
              placeholder='name@example.com'
              required
            />
          </Form.Group>
          {/* disclaimer */}
          {error.status && error.action === "submit" && (
            <div className='d-flex justify-content-center mt-2'>
              <p className='text-danger text-center m-1'> {error.message}</p>
            </div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          type="submit"
          >
          Submit
        </Button>
      </Modal.Footer>
    </Form>)
          }
        </Modal>
        <section className='banner-section'>
          <Container fluid>
            <Container>
              <Row className='align-items-center'>
                <Col md={12}>
                  <div className='banner-content contract-banner-content'>
                    <h1>Start Your Free Review</h1>
                    <div className='banner-image'>
                      <img
                        src='/assets/images/contract-image.png'
                        alt='Contract 1'
                      />
                    </div>
                    <div className='upload-contract-file d-flex justify-content-center'>
                      <input
                        type='file'
                        name='contractFile'
                        id='contractFile'
                        accept='.pdf'
                        onChange={handelFileUpload}
                        disabled={
                          loading?.active && loading?.action === "submit"
                        }
                      />
                      <label
                        htmlFor='contractFile'
                        className='btn btn-primary d-flex'
                        style={{
                          width: "fit-content",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {loading?.active && loading?.action === "submit" && (
                          <Spinner
                            animation='grow'
                            size='sm'
                            style={{ color: "inherit", marginRight: "10px" }}
                          />
                        )}{" "}
                        Upload Your Proposal
                      </label>
                    </div>

                    <div className='d-flex justify-content-center mt-2'>
                      {error.status && error.action === "submit" && (
                        <p className='text-danger text-center m-1'>
                          {" "}
                          {error.message}
                        </p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className='why-check-solar-section'>
          <Container fluid>
            <Container>
              <Row className='align-items-center'>
                <Col md={6}>
                  <div className='why-check-solar-image'>
                    <img
                      src='/assets/images/solar-check-image.png'
                      alt='Solar'
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className='why-check-solar-content'>
                    <h3>Why you need a Solar Check</h3>
                    <p>
                      Solar can be a great choice for you, your home, and the
                      environment - but it can also lock you into a 20+ year
                      contract that sends your bill through the roof.
                    </p>
                    <p>
                      SolarCheckr reviews your offer, provides you a simple deal
                      score and identifies over 20 of the most common red-flags
                      to help you make sure you’re getting a good deal - for
                      today and tomorrow.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className='solar-deals-section'>
          <Container fluid>
            <Container>
              <Row>
                <Col md={12}>
                  <div className='solar-deals-main-heading'>
                    <h1>
                      Learn more about how we protect you from bad solar deals
                    </h1>
                    <Link to='/about' className='btn btn-light'>
                      Learn More
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row className='align-items-center'>
                <Col md={6}>
                  <div className='solar-deals-content'>
                    <h3>What is a Solar Check?</h3>
                    <p>
                      A detailed review of a proposal or contract from a
                      developer. We analyze the offer, compare the cost and
                      terms to industry best practices, identify red flags and
                      provide you with a simple score and count of the red-flags
                      we identified.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='solar-deals-image'>
                    <img
                      src='/assets/images/solar-deals-image.png'
                      alt='Contract'
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className='data-secure-section'>
          <Container fluid>
            <Container>
              <Row className='align-items-center'>
                <Col md={5}>
                  <div className='data-secure-image'>
                    <img
                      src='/assets/images/data-secure-image.png'
                      alt='Solar'
                    />
                  </div>
                </Col>
                <Col md={7}>
                  <div className='data-secure-content'>
                    <h3>Is my data secure?</h3>
                    <p>
                      SolarCheckr will not sell your personal information to
                      third parties, and all data is transferred via a secure,
                      encrypted connection. You will never receive a sales call
                      as a result of getting a Solar Check.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className='solar-check-cost-section'>
          <Container fluid>
            <Container>
              <Row className='align-items-center'>
                <Col md={6}>
                  <div className='solar-check-cost-content'>
                    <h3>How much does a Solar Check cost?</h3>
                    <p>
                      A basic review is free, premium reports with deeper
                      analysis and customized strategies to help you negotiate
                      or find a better deal are $100.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='solar-check-cost-image'>
                    <img
                      src='/assets/images/solar-check-cost-image.png'
                      alt='Solar'
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </div>
    </>
  );
}

export default ContractUpload;
