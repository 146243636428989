import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const About = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <div className="about-page-wrapper">
        <section className="banner-section">
          <Container fluid>
            <Container>
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="banner-content about-banner-content">
                    <h1>
                      Empowering Homeowners with Independent Solar Insights
                    </h1>
                    <div className="banner-image">
                      <img src="/assets/images/logo.png" alt="Logo" />
                    </div>
                    <p>
                      SolarCheckr is not your average solar energy platform. We
                      go above and beyond by offering in-depth, independent
                      assessments of residential solar projects. In a world
                      where solar energy is gaining traction, our mission is to
                      demystify the complexities of solar installation. We
                      provide homeowners with comprehensive and unbiased
                      reviews, ensuring that they have all the information they
                      need to make informed decisions about their specific solar
                      needs and options.
                    </p>
                  </div>
                  <div className="about-solar-image">
                    <img
                      src="/assets/images/about-solar-image.png"
                      alt="About Solar"
                      className="w-100"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
        <section className="founder-section">
          <Container fluid>
            <Container>
              <Row>
                <Col md={6}>
                  <div className="founder-photo">
                    <img
                      src="/assets/images/Headshot.jfif"
                      alt="Kyle Haas"
                      className="w-100"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="founder-description">
                    <h2>Kyle Haas - CEO</h2>
                    <p>
                      Kyle has over 13 years of experience working in clean
                      energy - from working at solar development firms, as a
                      utility consultant, a clean energy program manager and
                      government policy analyst, Kyle has deep experience
                      leading large teams in creating the future energy system.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
        <br />
        <section className="free-solar-section">
          <div className="free-solar-content">
            <h2>Get Your Free Solar Check Today</h2>
            <p>
              Get a free deal rating and red-flag count broken down by key
              sections.
            </p>
            <Link to="/contract-upload" className="btn btn-primary">
              Check Your Deal
            </Link>
          </div>
        </section>

        <section className="economic-progress-section">
          <Container fluid>
            <Container>
              <Row>
                <Col md={6}>
                  <div className="economic-progress-content">
                    <h3>
                      Solar Energy:
                      <br /> A Catalyst for Environmental and Economic Progress
                    </h3>
                    <p>
                      SolarCheckr is not your average solar energy platform. We
                      go above and beyond by offering in-depth, independent
                      assessments of residential solar projects. In a world
                      where solar energy is gaining traction, our mission is to
                      demystify the complexities of solar installation.
                    </p>
                    <p>
                      We provide homeowners with comprehensive and unbiased
                      reviews, ensuring that they have all the information they
                      need to make informed decisions about their specific solar
                      needs and options.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="economic-progress-image">
                    <img
                      src="/assets/images/economic-progress-image.png"
                      alt="Economic Progress"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className="solar-checkr-advantage-section">
          <Container fluid>
            <Container>
              <Row>
                <Col md={6}>
                  <div className="solar-checkr-advantage-image">
                    <img
                      src="/assets/images/solar-checkr-advantage-image.png"
                      alt=""
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="solar-checkr-advantage-content">
                    <h3>
                      Overcoming Misinformation: The SolarCheckr Advantage
                    </h3>
                    <p>
                      In an industry filled with sales sites and sales-driven
                      agendas, SolarCheckr offers a refuge for unbiased
                      information. We tackle common misconceptions and obstacles
                      that homeowners encounter when contemplating solar energy.
                    </p>
                    <p>
                      For example, the intermittent nature of solar power and
                      the difficulties related to storage and connectivity are
                      frequently downplayed in sales presentations. Our
                      objective is to provide homeowners with accurate and
                      comprehensive insights, empowering them to navigate these
                      intricacies with clarity and confidence.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className="solar-data-scure-section" id="solar-data-secure">
          <Container fluid>
            <Container>
              <Row>
                <Col md={6}>
                  <div className="solar-data-scure-content">
                    <img
                      src="/assets/images/s-d-secure-icon-1.png"
                      alt="Icon 1"
                    />
                    <h3>What is SolarCheckr</h3>
                    <p>
                      A detailed review of a proposal or contract from a
                      developer. We analyze the offer, compare the cost and
                      terms to industry best practices, identify red flags and
                      provide you with a simple score and count of the red-flags
                      we identified.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="solar-data-scure-content">
                    <img
                      src="/assets/images/s-d-secure-icon-2.png"
                      alt="Icon 1"
                    />
                    <h3>Is my data secure?</h3>
                    <p>
                      SolarCheckr will not sell your personal information to
                      third parties, and all data is transferred via a secure,
                      encrypted connection.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className="diverse-user-section">
          <Container fluid>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="diverse-user-content">
                    <h3>
                      Our Diverse User Base:
                      <br /> Informed, Engaged, and Empowered
                    </h3>
                    <p>
                      SolarCheckr caters to a wide spectrum of users, each with
                      unique motivations but united by a common goal of making
                      informed solar decisions. From environmentally conscious
                      homeowners motivated by reducing their carbon footprint to
                      economically driven individuals seeking cost savings and
                      energy independence, our user base is diverse. Our
                      platform provides them with the necessary tools and
                      information to navigate the solar decision-making process
                      effectively.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="user-review-box">
                    <p>
                      I had no idea if I was getting a good deal on my solar
                      project until I used SolarCheckr.{" "}
                    </p>
                    <div className="user-box">
                      <img
                        src="/assets/images/third.jpg"
                        style={{ width: "15%", borderRadius: "50%" }}
                        alt="User"
                      />
                      <p>Michelle M.</p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="user-review-box">
                    <p>
                      SolarCheckr saved me over $5,000 on my project in less
                      than 5 minutes.
                    </p>
                    <div className="user-box">
                      <img
                        src="/assets/images/second.jpeg"
                        style={{ width: "24%", borderRadius: "50%" }}
                        alt="User"
                      />
                      <p>Kim S.</p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="user-review-box">
                    <p>
                      I already had an offer, but I needed to know if it was a
                      good one. SolarCheckr made that easy.
                    </p>
                    <div className="user-box">
                      <img
                        src="/assets/images/first.jpeg"
                        style={{ width: "15%", borderRadius: "50%" }}
                        alt="User"
                      />
                      <p>Kevin M.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>

        <section className="about-detail-section">
          <Container fluid>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="about-detail-main">
                    <h1>
                      Deep Dive into the 'Checkr' Dimensions:
                      <br /> Equip, Installer, Cost, Contract
                    </h1>
                    <div className="about-detail-box">
                      <h2>Project and Equipment</h2>
                      <p>
                        We delve into considerations related to the physical
                        project and related equipment components and
                        configurations, guiding homeowners towards choices that
                        are suitable for the context of their physical location,
                        specific energy needs and environmental conditions.
                      </p>
                    </div>
                    <div className="about-detail-box">
                      <h2>Installer</h2>
                      <p>
                        Recognizing the critical role of installers, we assess
                        their credentials, experience, and customer satisfaction
                        records to ensure reliable and efficient solar
                        installations.
                      </p>
                    </div>
                    <div className="about-detail-box">
                      <h2>Cost</h2>
                      <p>
                        We provide a transparent breakdown of solar installation
                        costs, accounting for factors like the type of solar
                        panel, location-specific incentives, and the impact of
                        supply chain constraints.
                      </p>
                    </div>
                    <div className="about-detail-box">
                      <h2>Contract</h2>
                      <p>
                        Our comprehensive review of contract details focuses on
                        protecting homeowners' interests, covering aspects like
                        warranties, service terms, and any hidden clauses.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </div>
    </>
  );
};





export default About;